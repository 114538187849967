<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Menu</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">Forms</h2>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="menuUpdate">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    v-model="form.name"
                    class="form-control"
                    id=""
                  />
                </div>
              </div>
              <div class="card-footer text-right">
                <button class="btn btn-primary mr-1" type="submit">
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "EditMenu",
  data() {
    return {
      name: "Edit Menu",
      form: {
        name: "",
      },
      errors: {},
    };
  },
  mounted() {
    let id = this.$route.params.id;
    axios
      .get(this.$store.state.api + "menu/" + id, {
        headers: {
          Authorization: this.$store.state.token,
        },
      })
      .then((res) => {
        this.form = res.data.data;
        console.log(this.form);
      })
      .catch((err) => console.log(err));
  },
  methods: {
    menuUpdate() {
      let id = this.$route.params.id;
      axios
        .post(this.$store.state.api + "menu/update/" + id, this.form, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your menu has been updated!");
          this.$router.push({ name: "Menu" });
        })
        .catch((error) => {
          this.errors = error.response.data;
        });
    },
  },
};
</script>

<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{
                      name: 'MenuCreate',
                      query: { menu_id: this.$route.query.id },
                    }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="menu"
                item-key="id"
                :search="search"
                :items-per-page="menu.length"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.name }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'MenuChild',
                          query: { menu_id: row.item.id },
                        }"
                        class="badge badge-info mr-1"
                      >
                        Children
                      </router-link>
                      <router-link
                        :to="{ name: 'MenuEdit', params: { id: row.item.id } }"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                      <button
                        class="badge badge-danger mr-1"
                        @click="deleteData(row.item.id)"
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { MenuRepository } from "../../../repositories/RepositoryFactory";

export default {
  name: "Menu",
  data() {
    return {
      search: "",
      name: "Menu",
      menu: [],
      token: localStorage.getItem("token"),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      MenuRepository.get().then(({ data }) => {
        this.menu = data.data;
      });
    },
    deleteData(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          MenuRepository.delete(id).then((res) => {
            swal("Poof! Your venue file has been deleted!", {
              icon: "success",
            });
            this.getData();
          });
        } else {
          swal("Your menu file is safe!");
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
        { text: "Action" },
      ];
    },
  },
};
</script>

import Menu from "./Menu.vue"
import MenuCreate from "./Create.vue"
import MenuEdit from "./Edit.vue"


export default [
    {
        path: "/menu",
        component: Menu,
        name: "Menu"
    },
    {
        path: "/menu/create",
        component: MenuCreate,
        name: "MenuCreate"
    },
    {
        path: "/menu/edit/:id",
        component: MenuEdit,
        name: "MenuEdit"
    },
]